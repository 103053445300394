import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import HealthyHeartsBanner from './HealthyHeartsBanner.jsx';
import HealthyHeartsNavgrid from './HealthyHeartsNavgrid.jsx';
import HealthyHeartsHelmet from './HealthyHeartsHelmet.jsx';

import { connect } from 'react-redux';

class HealthyHeartsLayout extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            compData: [
                ["A happy dog needs a healthy heart", null],
                ["Circulatory system", "Circulatory system"],
                ["Heart facts", "Heart facts"],
                ["Keeping your dog's heart healthy", "Your dog's health"],
                ["A healthy diet for a healthy dog", "Diet"],
                ["Keeping your dog in great shape", "Exercise"],
                ["Your vet is there to help", "Routine check-ups"],
            ],
            current: this.props.healthyHeartsPage,
        };
    }

    static getDerivedStateFromProps(props){
        return {
            current: props.healthyHeartsPage,
        }
    }

    render(){
        return (
            <StaticQuery
                query={graphql`
                    query {
                        healthyHeartsBannerImage: file(relativePath: { eq: "healthyHearts/healthy-hearts-banner-cropped.png" }) {
                            childImageSharp {
                                fluid(maxWidth: 1440, maxHeight: 659) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                `}
                render={(data) => (
                    <div>
                        <HealthyHeartsBanner compInfo={this.state} bannerImg={data.healthyHeartsBannerImage}/>
                        <HealthyHeartsHelmet titleToUse={this.state.compData[this.state.current][0]}/>
                            {this.props.children}
                        <HealthyHeartsNavgrid {...this.props}/>
                    </div>
                )}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        healthyHeartsPage: state.healthyHeartsNavigationReducer,
    }
};

export default connect(
    mapStateToProps,
    null,
)(HealthyHeartsLayout);
