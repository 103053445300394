import React from 'react';
import { Link } from 'gatsby';
import style from './HealthyHeartsNavgrid.module.css';
import arrowSvg from '../../images/common/Arrow_Right.svg';

class HealthyHeartsNavgrid extends React.Component {
    render(){
        const styles = {
            buttonStyle: {
                "borderWidth"       : "0px",
                "borderColor"       : "#003087",
                "borderStyle"       : "solid",
                "textAlign"         : "inherit",
                "color"             : "inherit",
                "backgroundColor"   : "inherit",
                "borderBottom"      : "2px solid #003087",
            },
            rotate: {
                transform: 'rotate(270deg)',
            }
        }

        const navlinks = [
            ["circulatory-system", "Circulatory system"],
            ["heart-facts", "Heart facts"],
            ["your-dogs-health", "Your dog's health"],
            ["diet", "Diet"],
            ["exercise", "Exercise"],
            ["routine-check-ups", "Routine check-ups"]
        ]

        // let isIE = /*@cc_on!@*/false || !!document.documentMode;
        let isIE = false;

        if( isIE ){
            return (
                <div className={style.HealthyHeartsNavgridContainer}>
                    <div className={style.HealthyHeartsNavgridIE}>
                        {
                            navlinks.map((el, i) => {
                                if ((this.props.healthyHeartsPage-1) === i) {
                                    return (
                                        <div className={style.LinkBoxContainer} key={i}>
                                            <button className={style.HealthyHeartsNavgridLinkButtonIE} key={i}>
                                                <p>{el[1]}</p>
                                                <img src={arrowSvg} alt="Right arrow" style={styles.rotate}/>
                                            </button>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className={style.LinkBoxContainer} key={i}>
                                            <Link to={`/healthy-hearts/${el[0]}`} className={style.HealthyHeartsNavgridLinkIE} key={i}>
                                                <div className={style.HHNavgridLinkTableCell}>
                                                    <img src={arrowSvg} alt="Right arrow" />
                                                    <p>{el[1]}</p>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            )
        } else {
            return (
                <div className={style.HealthyHeartsNavgridContainer}>
                    <div className={style.HealthyHeartsNavgrid}>
                        {
                            navlinks.map((el, i) => {
                                if ((this.props.healthyHeartsPage-1) === i) {
                                    return (
                                        <button className={style.HealthyHeartsNavgridLink} key={i} style={styles.buttonStyle}>
                                            <p>{el[1]}</p>
                                            <img src={arrowSvg} alt="Right arrow" style={styles.rotate}/>
                                        </button>
                                    )
                                } else {
                                    return (
                                        <Link to={`/healthy-hearts/${el[0]}`} className={style.HealthyHeartsNavgridLink} key={i}>
                                            <p>{el[1]}</p>
                                            <img src={arrowSvg} alt="Right arrow"/>
                                        </Link>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            )
        }
    }
}

export default HealthyHeartsNavgrid;
