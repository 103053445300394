import React from 'react';
import Layout from '../../components/common/Layout.jsx';
import HealthyHeartsLayout from '../../components/healthy-hearts/HealthyHeartsLayout.jsx';

import { connect } from 'react-redux';
import { HEALTHY_HEARTS_NAVIGATION } from '../../state/actionTypes.js';

class Exercise extends React.Component {
    constructor(props){
        super(props);
        props.healthyHeartsNavigation(5);
    }

    render() {
        return (
            <Layout>
                <HealthyHeartsLayout>
                    <div className="HealthyHeartsMainContainer">
                        <div className="Content">
                            <div className="SectionContainer">
                                <p className="SectionText">Regular exercise is important to keep you and your dog in great shape. It's also fun for both of you!</p>
                                <p className="SectionText">Your dog's exercise routine should consist of daily walks, some play time with you, and behavioural training.</p>
                                <p className="SectionText">Just like a person, your dog's exercise requirements depend on their size, age and health. Your vet can tell you more.</p>
                            </div>
                        </div>
                    </div>
                </HealthyHeartsLayout>
            </Layout>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        healthyHeartsNavigation: healthyHeartsPage => dispatch({
            type: HEALTHY_HEARTS_NAVIGATION,
            healthyHeartsPage,
        })
    }
}

export default connect(null, mapDispatchToProps)(Exercise);
